.NumberCard {
  width: 30%;
  margin-right: 1vh;
  margin-bottom: 1vh;
  display: inline-block;
  text-align: left;
}
.NumberCard .card-header {
  background-color: rgba(0, 0, 0, 0.03);
  font-weight: bold;
}
.NumberCard .card-text {
  font-size: 80%;
}
.NumberCard .card-body {
  padding-bottom: 1vh;
}

.SubTitle {
  text-align: left;
  width: 100%;
  margin-top: 1vh;
}

.Footer {
  background-color: #343a40 !important;
  width: 100%;
  color: #939393;
  padding-bottom: 2vh;
}

.AlertMessage {
  width: 100%;
}

/* Session */
.SessionListFirst {
  width: 100%;
  text-align: left;
}
.SessionListFirst .card-header {
  background-color: #6a518c;
  color: white;
  font-weight: bold;
}
.SessionListFirst_list {
  text-align: justify;
  padding-inline-start: 0;
}
.SessionListFirst_list li {
  display: inline-block;
  width: 50%;
}

.SessionListSecond {
  width: 100%;
  text-align: left;
}
.SessionListSecond .card-header {
  background-color: #79c7d9;
  color: black;
  font-weight: normal;
}
.SessionListSecond_list {
  text-align: justify;
  padding-inline-start: 0;
}
.SessionListSecond_list li {
  display: inline-block;
  width: 50%;
}
.SessionListSecond_list li span {
  margin-right: 1vh;
}
.SessionListSecond .card_list {
  width: 100%;
  text-align: center;
}

.SessionCard {
  width: 45%;
  margin-right: 1vh;
  margin-bottom: 1vh;
  display: inline-block;
  text-align: left;
}
.SessionCard .card-header {
  background-color: rgba(0, 0, 0, 0.03);
  font-weight: bold;
}
.SessionCard .card-text {
  font-size: 80%;
}
.SessionCard .canBook {
  color: #d96055;
}
.SessionCard .canWaiting {
  color: #6a518c;
}
.SessionCard .canNotBook {
  color: #626473;
}

.Footer .copyright {
  background: #171717;
  width: 80%;
  padding: 2vh 2vh 2vh 2vh;
}

.BoardCard .card-video-top {
  flex-shrink: 0;
  width: 100%;
}

.VideoPost {
  width: 100%;
  text-align: left;
  padding: 2vh 1vh 1vh 1vh;
  border-bottom: 0.1vh solid;
}

.VideoPost video {
  width: 100%;
}

.NotificationModal img {
  width: 100%;
  height: 100%;
}

@media (min-width: 800px) {
  .Footer .copyright {
    width: 30%;
  }
}

.Feedback {
  white-space: pre-wrap;
}