.App {
  text-align: center;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.LogoBox {
  text-align: center;
  margin-top: 5vh;
}

.MainBox {
  margin: 5vh 5vh 5vh 5vh;
}

.text_left {
  text-align: left;
}
.text_center {
  text-align: center;
}
.text_right {
  text-align: right;
}
.text_space {
  width: 5vh;
}

.BookedSchedule {
  width: 100%;
  text-align: left;
  padding: 2vh 1vh 1vh 1vh;
  border-bottom: 0.1vh solid;
}

.BookedSchedule .cancle {
  text-align: center;
  padding: 1vh 1vh 1vh 1vh;
}

.Tag {
  border: solid 0.1vh lightgray;
  border-radius: 0.8vh;
  padding: 0.5vh 1vh 0.5vh;
  margin: 0.5vh;
  float: left;
  font-size: 1.8vh;
}

.schedule_icon {
  max-width: 3vh;
}

.card_div {
  width: 100%;
  padding-bottom: 2vh;
}

.card_list {
  width: 100%;
  text-align: center;
}

/* Layout shifter pattern */
.containerMain {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-flow: row wrap;
  flex-flow: row wrap;
}

.c1, .c2, .c3, .c4 {
  width: 100%;
}

.c3 {
  margin-top: 2vh;
}

.main {
  min-height: 50vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.c2_container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 2vh 2vh 0 2vh;
}

.c2_container .jumbotron {
  width: 100%;
}

.c3_container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 2vh 2vh 0 2vh;
  text-align: center;
}

.inputGroup {
  width: 100%;
  text-align: center;
  margin-bottom: 2vh;
}

.inputGroup button {
  min-width: 17%;
}
.inputGroup .btn-toolbar>*+* {
  margin-left: 1vh;
}
/* 
@media (min-width: 600px) {
  .c1 {
    width: 25%;
  }

  .c4 {
    width: 75%;
  }

} */


@media (min-width: 600px) {
  .c1 {
    width: 100%;
  }

  .c4 {
    width: 100%;
  }

}

@media (min-width: 800px) {
  .containerMain {
    width: 800px;
    margin-left: auto;
    margin-right: auto;
  }
  .c3_container {
    display: flex;
    flex-wrap: row wrap;
    flex-direction: inherit;
  }
  .f_c {
    width: 30%;
  }
}