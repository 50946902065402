.centerLogo {
  height: 16vh;
}

.LoginBox {
  margin: 5vh 0 0 0;
}

.Login-link {
  margin-top: 15px;
}
.Login-link a {
  font-size: 16px;
  text-decoration: underline;
  color: darkblue;
}